<template lang="pug">
GroupAssetContainer(
  :classes="dynamic_class"
  :draggable="true"
  :to="is_folder ? { name: VIEW.GROUP_ASSETS_FOLDER, params: { folder_id: item.item_id } } : null"
  @dragstart="dragStart"
  @dragend="dragEnd"
  @drop.prevent.stop="drop"
  @dragover.prevent.stop
  @dragenter.prevent.stop="dragEnter"
  @dragleave.prevent.stop="dragLeave"
  @click="selection.toggle(item)"
)
  div.flex.flex-align-center.flex-justify-end.relative(style="height: 100%;")
    CustomCheckbox(:padded="true" :value="selection.selected.includes(item.item_id)" @change="selection.toggle(item)")
  template
    FolderThumbnail(v-if="is_folder" :count="item?.meta?.original_assets?.total ?? 0")
    Thumbanil(v-else-if="item.item_type === 'file'" :asset="item" variant="file" :small="true")
    CreativeThumbnail(v-else :asset="item")
  span.fw-500(v-tooltip="{value: item.name}") {{ item.name }}
  span
    template(v-if="item.width != null") {{ item.width }}x{{ item.height }}
    template(v-else) -
  span {{ item.owner?.name ?? '-' }}
  span {{ item.created_on | DateFilter }}
  i.nexd-icon-32-edit(aria-hidden="true" @click.stop.prevent="openEditSidebar")
</template>

<script>
import styles from './GroupAssetsComponents.module.scss';

import { LISTITEM, VIEW } from '@master/constants';
import Selection from '@libs/Selection';

import FolderService from '@master/Services/FolderService';
import GroupAssetsLibraryService from '@master/Services/GroupAssetsLibraryService';

import DraggableListItemMixin from '@root/src/global/mixins/DraggableListItemMixin.vue';

import GroupAssetContainer from '@cm/Views/GroupAssets/Components/GroupAssetContainer.vue';
import CreativeThumbnail from '@cm/UI/Global/Thumbnail/CreativeThumbnail.vue';
import FolderThumbnail from '@cm/UI/Global/Thumbnail/FolderThumbnail.vue';
import Thumbnail from '@cm/UI/Global/Thumbnail/Thumbnail.vue';

import CustomCheckbox from '@master/UI/CustomCheckbox.vue';

export default {
  name: 'GroupAsset',
  mixins: [DraggableListItemMixin],

  components: {
    CustomCheckbox,
    GroupAssetContainer,
    CreativeThumbnail,
    FolderThumbnail,
    Thumbnail,
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },

    selection: {
      type: Selection,
      required: true,
    },

    dragging: {
      type: Boolean,
      default: false,
    },

    dropbox: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    is_folder() {
      return this.item.item_type === LISTITEM.FOLDER;
    },

    dynamic_class() {
      let classes = [styles.group_asset];

      if (this.is_folder) {
        classes.push(styles.folder);
      }

      if (this.dropbox) {
        return classes;
      }

      if (!this.selection.selected.includes(this.item.item_id)) {
        if (this.show_drag_message) {
          classes.push(styles.drag_over);
        }

        if (this.dragging && this.is_folder) {
          classes.push(styles.drop_area);
        }
      }

      return classes;
    },
  },

  data() {
    return {
      styles,
      LISTITEM,
      VIEW,

      show_drag_message: false,
    };
  },

  created() {
    this.prepareDraggableImage();
  },

  methods: {
    setDragging(value) {
      this.$emit('setDragging', value);
    },

    async dragStart(e) {
      if (this.dropbox) {
        return;
      }

      if (!this.selection.selected.includes(this.item.item_id)) {
        this.selection.toggle(this.item);
        await this.$nextTick();
      }

      this.setDragging(true);
      e.dataTransfer.setData('action', 'add-to-folder');

      let payload = {
        asset_originals: this.selection.selected_items,
        folders: this.selection.selected_folders,
      };

      const dragging_items = Object.values(payload).flat().length;

      e.dataTransfer.setData('application/payload', JSON.stringify(payload));
      e.dataTransfer.setDragImage(this.createDraggable(this.item.name, dragging_items, e), 0, 0);
    },

    dragEnd() {
      this.removeDraggable();
      this.setDragging(false);
      this.show_drag_message = false;
    },

    dragEnter() {
      if (!this.is_folder || this.dropbox) {
        return;
      }

      this.show_drag_message = true;
    },

    dragLeave(e) {
      if (!this.is_folder || this.dropbox) {
        return;
      }

      if (!e.currentTarget.contains(e.relatedTarget)) {
        this.show_drag_message = false;
      }
    },

    drop(e) {
      if (this.dropbox) {
        return this.$emit('addAssets', e);
      }

      this.setDragging(false);
      this.show_drag_message = false;

      if (!this.is_folder) {
        return;
      }

      if (e.dataTransfer.getData('action') !== 'add-to-folder') {
        return;
      }

      const payload = JSON.parse(e.dataTransfer.getData('application/payload'));

      if (payload.folders.includes(this.item?.item_id)) {
        return;
      }

      this.$http.post('v2/move', { folder_id: this.item.item_id, ...payload }).finally(() => {
        GroupAssetsLibraryService.removeItems([...payload.asset_originals, ...payload.folders]);
        this.selection.unselectAll();
      });
    },

    openEditSidebar() {
      if (this.is_folder) {
        FolderService.active.set(this.item);
      } else {
        GroupAssetsLibraryService.active.set(this.item);
      }
    },
  },
};
</script>
