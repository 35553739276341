<template lang="pug">
div(
  :class="classes"
  :data-number-of-items="count_string"
  @mousedown="$emit('mousedown', $event)"
  @mouseup="$emit('mouseup', $event)"
  @click="$emit('click', $event)"
)
  img(v-if="has_uri" @load="component_loading = false" @error="error = true" :src="asset_uri")
  Loading.small(v-if="component_loading || loading" :fill="true")
  slot
</template>

<script>
import Loading from '@master/UI/Loading.vue';

import styles from './Thumbnail.module.scss';

export default {
  name: 'Thumbnail',

  components: {
    Loading,
  },

  props: {
    asset: {
      type: Object,
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    count: {
      type: Number,
      default: -1,
    },

    small: {
      type: Boolean,
      default: false,
    },

    fill: {
      type: Boolean,
      default: false,
    },

    variant: {
      type: String,
      default: null,
      validator: value => ['file', 'folder', 'creative', 'styles', 'nobgimage'].includes(value),
    },

    bg: {
      type: String,
      default: null,
      validator: value => ['light', 'csv', 'unset'].includes(value),
    },

    dropdown: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      const classes = [styles.thumbnail];

      if (this.count > 0) {
        classes.push(styles.count);
      }

      if (this.small) {
        classes.push(styles.small);
      }

      if (this.fill) {
        classes.push(styles.fill);
      }

      if (this.variant != null) {
        classes.push(styles[this.variant]);
      }

      if (this.bg != null) {
        classes.push(styles[this.bg]);
      }

      if (this.has_uri) {
        classes.push(styles.nobgimage);
      }

      if (this.dropdown) {
        classes.push(styles.dropdown);
      }

      return classes;
    },

    uri() {
      return this.asset?.image_uri ?? this.asset?.thumbnail_url ?? null;
    },

    has_uri() {
      return this.uri != null && !this.error;
    },

    is_svg() {
      return this.has_uri && this.uri.endsWith('.svg');
    },

    asset_uri() {
      if (!this.has_uri) {
        return null;
      } else if (this.allow_svg && this.is_svg) {
        // this block is disabled until FW catches up with SVG support
        // currently backend now hacks the svg to be full asset size, so logic how to position it in thumbnail is not needed
        const thumbnail_width = this.element.width;
        const thumbnail_height = this.element.height;

        let position_x = 'center';
        let position_y = 'center';
        let size_x = 'contain';
        let size_y = '';

        if (this.asset?.settings?.SourceLayer != null) {
          const sw = this.asset.settings.SourceLayer.size.width;
          const sh = this.asset.settings.SourceLayer.size.height;
          const cw = this.asset.settings.CropLayer.size.width;
          const ch = this.asset.settings.CropLayer.size.height;

          const sx = this.asset.settings.SourceLayer.position.x;
          const sy = this.asset.settings.SourceLayer.position.y;

          size_x = thumbnail_width / (cw / sw) + 'px';
          size_y = thumbnail_height / (ch / sh) + 'px';

          position_x = `calc(50% + ${(thumbnail_width / cw) * sx}px)`;
          position_y = `calc(50% + ${(thumbnail_height / ch) * sy}px)`;
        }

        return {
          'background-image': 'url(' + this.asset.image_uri + ')',
          'background-position': `${position_x} ${position_y}`,
          'background-size': `${size_x} ${size_y}`,
        };
      }

      return this.uri;
    },

    count_string() {
      return this.count > 99 ? '99+' : this.count.toString();
    },
  },

  data() {
    return {
      styles,

      element: {
        width: null,
        height: null,
      },
      component_loading: false,
      error: false,
      allow_svg: false,
    };
  },

  mounted() {
    this.element.width = this.$el.clientWidth;
    this.element.height = this.$el.clientHeight;

    if (this.asset?.asset_uri != null) {
      this.component_loading = true;
    }
  },
};
</script>
