<template lang="pug">
TestLayout(title="Thumbnail")

  TestSection(subtitle="Default")
    Thumbnail(:asset="{image_uri: `${$cdn}dist/nexd/imgs/icecream-success.svg`}")

  TestSection(subtitle="Default empty")
    Thumbnail

  TestSection(subtitle="Default loading")
    Thumbnail(:loading="true")

  TestSection(subtitle="Bg Light")
    Thumbnail(variant="nobgimage" bg="light")

  TestSection(subtitle="Small Loading")
    Thumbnail(:loading="true" :small="true")

  TestSection(subtitle="Folder")
    FolderThumbnail

  TestSection(subtitle="Creative Empty")
    CreativeThumbnail

  TestSection(subtitle="Creative")
    CreativeThumbnail(:asset="{image_uri: `${$cdn}dist/assets/cm/nexd-com.svg`}")

  TestSection(subtitle="Styles (Survey)")
    Thumbnail(variant="styles" bg="unset")
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import CreativeThumbnail from '@cm/UI/Global/Thumbnail/CreativeThumbnail.vue';
import FolderThumbnail from '@cm/UI/Global/Thumbnail/FolderThumbnail.vue';
import Thumbnail from '@cm/UI/Global/Thumbnail/Thumbnail.vue';

export default {
  name: 'ThumbnailTest',

  components: {
    TestLayout,
    TestSection,

    CreativeThumbnail,
    FolderThumbnail,
    Thumbnail,
  },
};
</script>
