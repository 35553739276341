<template lang="pug">
div#app-wrapper.full-width
  CampaignNavbar(
    v-if="!not_found"
    :campaigns="campaigns"
    :folders="folders"
    :selection="selection"
    :show_unarchive_btn="show_unarchive_btn"
    :can_delete_campaigns="can_delete_campaigns"
    :can_delete_folders="can_delete_folders"
    :breadcrumbs="breadcrumbs"
    @archiveBulkHandler="archiveBulkHandler"
    @deleteBulkHandler="deleteBulkHandler"
  )

  StickyHeader(:key="is_sticky_header_visible")
    Breadcrumb(v-if="breadcrumbs != null" :items="breadcrumbs")
    template(v-if="is_sticky_header_visible")
      div.flex-grow
      SearchSelectSteps(
        v-model="active_filter"
        :options="filters"
        :opts="active_filter_options"
        :search="false"
        filter="Filter"
        @update="activeFilterChangeHandler"
      )
      SearchInput(v-model="query" @input="openSearch")

    template(v-slot:table_header v-if="is_sticky_header_visible")
      div#campaign-table
        ListHeader(:service="CampaignService" :selection="selection" :select_all_disabled="select_all_disabled")

  div#campaigns-list(v-content)
    CampaignCreativeNotFound(v-if="not_found" :type="in_folder ? 'folder' : 'campaign'")
    Loading(v-else-if="items_loading")
    NoListItem(v-else-if="items_empty")
    template(v-else)
      div.custom-tbody
        template(v-for="item of items")
          Folder(
            v-if="item.item_type === LISTITEM.FOLDER"
            :key="item.folder_id"
            :folder="item"
            :selection="selection"
          )
          Campaign(
            v-else-if="item.item_type === LISTITEM.CAMPAIGN"
            :key="item.campaign_id"
            :campaign="item"
            :selection="selection"
            @load="loadCampaignsList"
          )
      div.loading-more-campaigns(v-if="loading_more_items && can_load_more")
        Loading
      UpsellingFooter.text-center(v-if="items != null && items.length > 0" type="campaigns" v-observe-visibility="loadMoreItems")
  EditorSidebar
</template>

<script>
import EditorSidebar from '@root/src/global/sidebar/edit/EditorSidebar.vue';
import CampaignTraits from '@cm/Views/Campaigns/Traits/CampaignTraits.vue';

import Loading from '@master/UI/Loading.vue';
import UpsellingFooter from '@master/UI/UpsellingFooter.vue';
import SearchInput from '@master/UI/Input/SearchInput.vue';
import SearchSelectSteps from '@master/UI/SearchSelect/SearchSelectSteps.vue';
import CampaignCreativeNotFound from '@master/UI/CampaignCreativeNotFound.vue';
import StickyHeader from '@master/UI/StickyHeader/StickyHeader.vue';

import CreativesService from '@master/Services/CreativesService';
import CampaignService from '@master/Services/CampaignService';
import FolderService from '@master/Services/FolderService';
import FilterService from '@master/Services/FilterService';
import KeywordsService from '@master/Services/Cache/KeywordsService';

import Campaign from '@cm/Views/Campaigns/Campaign/Campaign.vue';
import Folder from '@cm/Views/Campaigns/Folder/Folder.vue';
import CampaignNavbar from '@cm/Views/Campaigns/CampaignNavbar.vue';
import NoListItem from '@cm/UI/Global/NoListItem.vue';
import Breadcrumb from '@cm/UI/Global/Breadcrumb.vue';
import ListItem from '@cm/UI/Global/ListItem.vue';
import ListHeader from '@cm/UI/Global/ListHeader.vue';

import { SEARCH, STATUS, LISTITEM } from '@master/constants';
import Selection from '@libs/Selection';
import FilterSelection from '@libs/FilterSelection';

export default {
  name: 'CampaignView',
  mixins: [CampaignTraits],

  components: {
    EditorSidebar,
    Loading,
    Campaign,
    Folder,
    UpsellingFooter,
    CampaignNavbar,
    NoListItem,
    SearchInput,
    SearchSelectSteps,
    Breadcrumb,
    CampaignCreativeNotFound,
    ListItem,
    ListHeader,
    StickyHeader,
  },

  computed: {
    folders() {
      if (!this.items) return this.items;
      return this.items.filter(item => item.item_type === 'folder');
    },

    campaigns() {
      if (!this.items) return this.items;
      return this.items.filter(item => item.item_type === 'campaign');
    },

    in_folder() {
      return this.$route?.params?.folder_id != null;
    },

    items_loading() {
      return this.items == null;
    },

    items_empty() {
      /** no campaigns or folders have been created */
      return !this.items?.length;
    },

    select_all_disabled() {
      return this.items_loading || this.items_empty;
    },

    is_sticky_header_visible() {
      return !this.items_empty;
    },

    is_bulk_selected() {
      return this.selection.is_some_selected && this.selection.selected_items.length + this.selection.selected_folders.length === this.items?.length;
    },

    can_delete_campaigns() {
      if (!this.selection.has_items_selection) return true;

      return this.selection.selected_items.every(campaign_id => {
        const c = this.campaigns.find(campaign => campaign.campaign_id === campaign_id);
        return c?.actions?.can_remove ?? false;
      });
    },

    can_delete_folders() {
      if (!this.selection.has_folders_selection) return true;

      return this.selection.selected_folders.every(folder_id => {
        const f = this.folders.find(folder => folder.folder_id === folder_id);
        return f?.actions?.can_remove ?? false;
      });
    },

    show_unarchive_btn() {
      if (!this.campaigns) return false;

      /** if all campaigns are archieved in the view */
      if (this.campaigns.every(campaign => campaign.status === STATUS.ARCHIVED)) return true;

      /** if all selected campaigns are archieved */
      return (
        this.selection.has_items_selection &&
        this.selection.selected_items.every(campaign_id => {
          const c = this.campaigns.find(campaign => campaign.campaign_id === campaign_id);
          if (c == null) return false;
          return c.status === STATUS.ARCHIVED;
        })
      );
    },

    active_filter_options() {
      switch (this.active_filter) {
        case 'layouts':
          return this.layout_options;
        case 'status':
          return this.status_options;
        case 'types':
          return this.type_options;
        case 'keywords':
          return this.keyword_options;
        case 'advertisers':
          return this.advertiser_options;
        default:
          return [];
      }
    },

    delete_disabled() {
      if (!this.selection.is_some_selected) return true;
      if (this.selection.has_items_selection && !this.can_delete_campaigns) return true;
      return this.selection.has_folders_selection && !this.can_delete_folders;
    },

    filters() {
      return [
        { value: 'layouts', label: 'Layout' },
        { value: 'status', label: 'Status' },
        { value: 'types', label: 'Ad Type' },
        { value: 'keywords', label: 'Keyword', disabled: !this.keyword_options?.length },
        { value: 'advertisers', label: 'Advertiser', disabled: !this.advertiser_options?.length },
      ];
    },
  },

  data() {
    return {
      LISTITEM,
      CampaignService,

      user: null,
      breadcrumbs: null,
      not_found: false,
      items: null,

      selection: new Selection(),
      filter_selection: new FilterSelection(),

      active_campaign: null,
      active_folder: null,

      loading_more_items: false,
      can_load_more: true,

      // options
      layout_options: [],
      status_options: [],
      type_options: [],
      keyword_options: [],
      advertiser_options: [],

      //filters
      query: '',
      active_filter: null,
    };
  },

  created() {
    // reset any info in the creatives service
    CreativesService.reset();

    FilterService.load();
    FilterService.subscribe(state => {
      if (state == null) return;

      this.layout_options = state['creatives.layout'];
      this.status_options = state.status;
      this.type_options = state['creatives.type'];
      this.keyword_options = state['campaigns.keywords'];
      this.advertiser_options = state['campaigns.advertisers'];
    }, this);

    KeywordsService.load(); /** preload keywords for inputs */

    this.$user.subscribe(user => {
      this.user = user;
    }, this);

    // subscribe after user is loaded
    CampaignService.subscribe(state => {
      this.breadcrumbs = state?.breadcrumbs;
      this.items = state?.items;
      this.can_load_more = state?.can_load_more;
      this.not_found = state?.not_found === true;

      this.loading_more_items = false;

      this.selection.data = this.items;

      if (state != null) {
        if (!FolderService.current.is(state.folder)) {
          FolderService.current.set(state.folder);
        }

        this.resetSidebar();
      }
    }, this);

    this.loadCampaignsList(); /** must be after campaign service subscription */
  },

  methods: {
    loadCampaignsList() {
      this.items = null;

      CampaignService.active.set(null);
      CampaignService.load();
    },

    loadMoreItems(is_visible) {
      if (!is_visible || this.loading_more_items) return;

      this.loading_more_items = true;
      CampaignService.loadMore();
    },

    archiveBulkHandler(action) {
      if (!this.selection.has_items_selection) return;

      this.$http.post(`campaigns/bulk/${action}`, { ids: this.selection.selected_items }).then(updated_campaigns => {
        CampaignService.updateItems(updated_campaigns);
        this.selection.unselectAll();
      });
    },

    constructDeleteBulkConfirmMessage() {
      let message = 'Are you sure you want to delete';

      if (this.selection.has_items_selection) {
        message += ` ${this.selection.selected_items.length} campaign${this.selection.selected_items.length > 1 ? 's' : ''}`;
      }

      if (this.selection.has_items_selection && this.selection.has_folders_selection) {
        message += ' and';
      }

      if (this.selection.has_folders_selection) {
        message += ` ${this.selection.selected_folders.length} folder${this.selection.selected_folders.length > 1 ? 's' : ''}`;
      }

      return `${message}?`;
    },

    async deleteBulkHandler() {
      if (this.delete_disabled) return;

      if (!(await this.$confirm(this.constructDeleteBulkConfirmMessage()))) return;

      if (this.selection.has_items_selection) {
        this.$http
          .delete('campaigns/bulk', { ids: this.selection.selected_items })
          .then(campaign_ids => {
            CampaignService.removeItems(campaign_ids);
          })
          .finally(() => {
            this.resetSidebar();
            this.selection.selected_items = [];
          });
      }

      if (this.selection.has_folders_selection) {
        this.$http
          .delete('v2/folder', { folders: this.selection.selected_folders })
          .then(foldr_ids => {
            CampaignService.removeItems(foldr_ids);
          })
          .finally(() => {
            this.resetSidebar();
            this.selection.selected_folders = [];
          });
      }
    },

    async resetSidebar() {
      if (!this.$sidebar) return;
      this.$sidebar.reset();

      if (this.breadcrumbs?.length > 0) {
        const index = this.breadcrumbs.length > 1 ? this.breadcrumbs.length - 2 : this.breadcrumbs.length - 1;
        const breadcrumb = this.breadcrumbs[index];
        this.$sidebar.setBack(breadcrumb.label, breadcrumb.url);
      } else {
        /** Fallback if breadcrumbs doesn't exist, which should not happen. */
        this.$sidebar.setBack('My Campaigns', '/');
      }

      const folder = FolderService.current.get();

      if (this.in_folder && folder?.actions?.can_update) {
        this.$sidebar.setEdit('Folder Settings', () => {
          FolderService.active.set(folder);
        });
      }
    },

    updateFilterService(filters) {
      if (filters == null) return;

      this.filter_selection.select(this.active_filter, filters);

      this.openSearch();
    },

    openSearch() {
      const params = { ...this.filter_selection.getParams() };
      if (this.query.trim() !== '') {
        params.search = [this.query.trim()];
      }

      const query = {
        scope: SEARCH.PERSONAL,
      };

      if (this.$route.params?.active_item_id) {
        const active_item_id = this.$route.params?.active_item_id;

        // when NEXD admins init search, we allow searching from anyone
        if (this.$user.isNexdAdmin()) {
          query.scope = SEARCH.PERSONAL;
          query.id = active_item_id;
        }

        // if the active item is a group, make sure the scope is group
        if (active_item_id.startsWith('g_')) {
          query.scope = SEARCH.GROUP;
        }
      }

      for (const key in params) {
        query[key] = params[key].join(',');
      }

      this.$router.push({ name: 'search', query });
    },

    activeFilterChangeHandler(filters) {
      if (this.active_filter == null) return;
      this.updateFilterService(filters);
    },
  },
};
</script>
