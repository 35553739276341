<template lang="pug">
SecondaryNavbar

  //- new folder
  div.navbar-section(v-if="$route?.params?.creative_id == null")
    Button(
      type="dropdown"
      label="New Folder"
      :disabled="disable_create_folder"
      :tooltip="disable_create_folder_tooltip"
      v-google-analytics="{ key: 'click', element_name: 'create new campaign folder' }"
      @click="createFolder"
    )
      template(v-slot:prefix)
        IconFolder

  //- icons
  div.navbar-section(v-if="selection.is_some_selected")
    div.bulk-action-icons(v-tooltip="{value: !selection.is_some_selected ? 'You have not selected any campaigns yet.' : null, position: 'bottom'}")
      template(v-for="(icon_settings, icon) in icons")
        i(
          v-if="icon_settings.visible"
          :key="icon"
          :class="{[icon]: true, 'disabled': icon_settings.disabled, 'cursor-pointer': true}"
          v-tooltip="{...icon_settings.tooltip, position: 'bottom'}"
          @click="icon_settings.cta"
        )

  div.flex-grow
  Button(
    type="primary"
    label="New Campaign"
    :animate="true"
    :disabled="disable_create"
    :tooltip="disable_create_tooltip"
    v-google-analytics="{ key: 'click', element_name: 'create new campaign' }"
    v-user-guiding="'new-campaign-button'"
    @click="createCampaign"
  )
    template(v-slot:prefix)
      IconAdd
  CopyMoveModal(v-if="copy_move" :selection="selection" :breadcrumbs="breadcrumbs" @close="closeCopyMoveModal")
</template>

<script>
import CampaignService from '@master/Services/CampaignService';
import FolderService from '@master/Services/FolderService';

import Button from '@master/UI/Buttons/Button.vue';
import IconAdd from '@master/UI/Buttons/Icons/IconAdd.vue';
import IconFolder from '@master/UI/Buttons/Icons/IconFolder.vue';
import SecondaryNavbar from '@master/UI/SecondaryNavbar/SecondaryNavbar.vue';

import CopyMoveModal from '@cm/UI/Global/Modals/CopyMove/CopyMoveModal.vue';

import { getFoldersDepth } from '@helpers/Global';
import { FOLDER } from '@master/constants';
import Selection from '@libs/Selection';

export default {
  name: 'CampaignNavbar',

  components: {
    Button,
    IconAdd,
    IconFolder,
    CopyMoveModal,
    SecondaryNavbar,
  },

  props: {
    campaigns: {
      type: Array,
      default: () => null,
    },

    folders: {
      type: Array,
      default: () => null,
    },

    folder: {
      type: Object,
      default: () => null,
    },

    selection: {
      type: Selection,
      required: true,
    },

    show_unarchive_btn: {
      type: Boolean,
      default: false,
    },

    can_delete_campaigns: {
      type: Boolean,
      default: false,
    },

    can_delete_folders: {
      type: Boolean,
      default: false,
    },

    breadcrumbs: {
      type: Array,
      default: () => null,
    },
  },

  computed: {
    active_user() {
      return this.$route?.params?.active_item_id ?? null;
    },

    disable_create() {
      return this.active_user != null;
    },

    disable_create_folder() {
      return this.disable_create || this.folders_depth >= 5;
    },

    disable_create_tooltip() {
      if (this.active_user != null) {
        return { value: 'You are currently in another campaign list. You can only create new campaigns under “My Campaigns”', position: 'bottom' };
      }

      return { value: null };
    },

    disable_create_folder_tooltip() {
      if (this.active_user != null) {
        return { value: 'You are currently in another campaign list. You can only create new folders under “My Campaigns”', position: 'bottom' };
      }

      if (this.folders_depth >= 5) {
        return { value: 'You can only create up to 5 levels of folders', position: 'bottom' };
      }

      return { value: null };
    },

    delete_bulk_disabled() {
      if (!this.selection.is_some_selected) return true;
      if (this.selection.has_items_selection && !this.can_delete_campaigns) return true;
      return this.selection.has_folders_selection && !this.can_delete_folders;
    },

    delete_bulk_tooltip() {
      if (!this.selection.is_some_selected) return { value: null };

      let warning_tooltip = null;

      if (!this.can_delete_campaigns && !this.can_delete_folders) {
        warning_tooltip = 'You have selected exported campaigns or folders with content. Please uncheck those and try again. NB! Campaigns with exported creatives and folders with content cannot be deleted.';
      } else if (!this.can_delete_campaigns) {
        warning_tooltip = 'You have selected exported campaigns, which cannot be deleted. Please uncheck those and try again. NB! If campaign is archived but it has exported creatives, then it cannot be deleted.';
      } else if (!this.can_delete_folders) {
        warning_tooltip = `
          Uncheck selected folders with content to delete; only empty folders can be removed.
        `;
      }

      if (warning_tooltip != null) {
        return { value: warning_tooltip, class: 'advanced warning', position: 'right' };
      }

      let message = 'Delete';

      if (this.selection.has_items_selection) {
        message += ` selected campaign${this.selection?.selected_items?.length > 1 ? 's' : ''}`;
      }

      if (this.selection.has_items_selection && this.selection.has_folders_selection) {
        message += ' and';
      }

      if (this.selection.has_folders_selection) {
        message += ` selected folder${this.selection?.selected_folders?.length > 1 ? 's' : ''}`;
      }

      return { value: message };
    },

    icons() {
      return {
        'nexd-icon-32-move': {
          visible: true,
          disabled: !this.selection.is_some_selected,
          tooltip: { value: 'Move', class: 'simple' },
          cta: () => this.openCopyMoveModal(),
          folder: true,
        },
        'nexd-icon-32-remove-from-folder': {
          visible: this.$route.params.folder_id != null,
          disabled: !this.selection.is_some_selected,
          tooltip: { value: 'Remove selected items from folder' },
          cta: () => this.removeFromFolder(),
        },
        'nexd-icon-32-unarchive': {
          visible: this.show_unarchive_btn && this.selection.has_items_selection,
          disabled: !this.selection.has_items_selection || this.selection.has_folders_selection,
          tooltip: { value: !this.selection.has_items_selection ? null : 'Unarchive all selected campaigns' },
          cta: () => this.$emit('archiveBulkHandler', 'unarchive'),
        },
        'nexd-icon-32-archive1': {
          visible: !this.show_unarchive_btn && this.selection.has_items_selection,
          disabled: !this.selection.has_items_selection || this.selection.has_folders_selection,
          tooltip: { value: !this.selection.has_items_selection ? null : this.archive_icon_tooltip },
          cta: () => this.$emit('archiveBulkHandler', 'archive'),
        },
        'nexd-icon-32-delete': {
          visible: true,
          disabled: this.delete_bulk_disabled,
          tooltip: this.delete_bulk_tooltip,
          cta: () => this.$emit('deleteBulkHandler'),
        },
      };
    },

    archive_icon_tooltip() {
      return this.selection.has_folders_selection ? 'Folders could not be archived' : 'Archive all selected campaigns';
    },

    selection_parent_id() {
      if (this.selection?.selected_folders.length > 0) {
        return this.folders?.find(folder => this.selection.selected_folders.includes(folder.folder_id))?.parent_id;
      }

      if (this.selection?.selected_items.length > 0) {
        return this.campaigns?.find(campaign => this.selection.selected_items.includes(campaign.campaign_id))?.folder_id;
      }

      return null;
    },

    folders_depth() {
      return getFoldersDepth(this.breadcrumbs);
    },
  },

  data() {
    return {
      copy_move: false,
    };
  },

  methods: {
    openCopyMoveModal() {
      this.copy_move = true;
    },
    closeCopyMoveModal() {
      this.copy_move = false;
    },

    createCampaign() {
      if (this.disable_create) return;
      const campaign_id = `new-${Date.now()}`;
      const user = this.$user.get();

      const obj = {
        campaign_id,
        path: '/',
        item_id: campaign_id,
        item_type: 'campaign',
        name: 'New Campaign',
        folder_id: this.$route?.params?.folder_id ?? null,
        keywords: null,
        collaborators: [],
        status: 0,
        owner: {
          user_id: user.user_id,
          name: user.name,
        },
        creative_info: {
          totals: {
            total: 0,
            drafts: 0,
          },
        },
      };

      CampaignService.addItem(obj);
      CampaignService.active.set(obj);
    },

    createFolder() {
      if (this.disable_create) return;

      const folder_id = `new-${Date.now()}`;
      const user = this.$user.get();

      const obj = {
        folder_id,
        path: '/',
        item_id: folder_id,
        item_type: 'folder',
        name: 'New Folder',
        parent_id: this.$route?.params?.folder_id ?? null,
        status: 0,
        collaborators: [],
        type: FOLDER.CAMPAIGN,
        owner: {
          user_id: user.user_id,
          name: user.name,
        },
        creative_info: {
          totals: {
            total: 0,
            drafts: 0,
          },
        },
      };

      CampaignService.addItem(obj);
      FolderService.active.set(obj);
    },

    async removeFromFolder() {
      if (this.$route?.params?.folder_id == null) return;
      if (!(await this.$confirm('Are you sure you would like to remove items from folder?', 'This will move items back to campaign list.'))) return;

      const payload = {
        campaigns: this.selection.selected_items,
        folders: this.selection.selected_folders,
      };

      this.$http.delete(`v2/folder/${this.$route.params.folder_id}/items`, payload).then(success => {
        if (success) {
          CampaignService.removeItems([...this.selection.selected_items, ...this.selection.selected_folders]);
          this.selection.unselectAll();
        }
      });
    },
  },
};
</script>
