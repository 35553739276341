<template lang="pug">
div.modal.social-import
  div.backdrop
  div.modal-content
    div.modal-body

      template(v-if="no_accessible_pages")
        div.modal-head.flex-justify-center
         div.modal-title Seems like you have no access to any pages in Facebook

      section(v-else)
        div.modal-main-content.pt-32
          div.row.flex-align-center
            div.col-2
              Thumbnail(:small="true" :asset="{image_uri: active_facebook_page != null ? active_facebook_page.picture.data.url : null}")
            div.col-8
              Loading(v-if="loading.pages")
              SearchSelect(
                v-else-if="facebook_pages"
                v-model="selected_page_id"
                :options="page_options"
                :search="false"
                :disabled="loading.posts || page_options.length <= 1"
                @input="getFacebookPagePosts(null)")

          div.social-import-table.mt-32
            Loading(v-if="loading.posts")
            template(v-else-if="active_facebook_page != null && active_facebook_page.posts != null && active_facebook_page.posts.length > 0")
              div.pagination(v-if="active_facebook_page")
              table(v-sortable-table="active_facebook_page.posts")
                thead
                  tr
                    template(v-for="head of headers")
                      th(:data-key="head.key" v-tooltip="{value: head.tooltip}") {{head.title}}
                        i(v-if="head.icon" :class="head.icon")
                tbody
                  tr(
                    v-for="post of active_facebook_page.posts"
                    :class="{'active': active_facebook_post && active_facebook_post.id === post.id, 'disabled': post.full_picture == null}"
                    v-tooltip="{value: post.full_picture != null ? null : 'This post does not have any images or videos to import from'}"
                    @click="select(post)"
                  )
                    td
                      i.nexd-icon-16-check-full-circle.color-primary(aria-hidden="true")
                    td
                      Thumbnail(:small="true" :asset="{image_uri: post && post.full_picture ? post.full_picture : null}")
                    td {{post.created_time | DateFilter}}
                    td
                      div.trim {{post.message}}

                    // show reactions as the likes count, because likes are actually only thumb up reactions, but we want to have all combined reactions which are all under like button
                    td.text-center(v-tooltip="{value: post.full_picture == null ? null : 'Number of likes'}") {{post.reactions | ShortNumberFilter}}
                    td.text-center(v-tooltip="{value: post.full_picture == null ? null : 'Number of comments'}") {{post.comments | ShortNumberFilter}}
                    td.text-center(v-tooltip="{value: post.full_picture == null ? null : 'Number of views'}") {{ post.insights | ShortNumberFilter}}
                    td.text-center(v-tooltip="{value: post.full_picture == null ? null : 'Number of shares'}") {{post.shares | ShortNumberFilter}}
                tbody(v-if="loading.more")
                  tr
                    td(colspan="8")
                      Loading
            div(v-else) This page does not have any posts. Please make some posts on Facebook.

        div.modal-preview-content.pt-32
          div.fb-preview
            iframe(
              v-if="encoded_fb_post_url != null"
              :src="encoded_fb_post_url"
              width="350"
              height="731"
              style="border:none;overflow:hidden"
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay;clipboard-write;encrypted-media;picture-in-picture;web-share")
    Buttons.modal-footer
      Button(type="link-primary" label="Cancel" @click="cancel")
      Button(type="primary" label="Import" :animate="true" :disabled="loading.import || !active_facebook_post" @click="done")
</template>

<script>
import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Loading from '@master/UI/Loading.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import Thumbnail from '@cm/UI/Global/Thumbnail/Thumbnail.vue';
import Modal from '@master/UI/Modal.vue';

import { getUserFacebookPages, getFacebookPagePosts } from '@helpers/Facebook';
import { breakLongStrings } from '@helpers/Global';

export default {
  name: 'FbPageModal',
  extends: Modal,
  components: {
    Button,
    Buttons,
    Loading,
    SearchSelect,
    Thumbnail,
  },
  props: {
    token: String,
  },

  computed: {
    active_facebook_page() {
      if (this.selected_page_id && this.facebook_pages?.[this.selected_page_id]) {
        return this.facebook_pages[this.selected_page_id];
      }
      return null;
    },
  },

  data() {
    return {
      loading: {
        pages: false,
        posts: true,
        more: false,
        details: false,
      },
      no_accessible_pages: false,
      paging: null,
      selected_page_id: null,
      active_facebook_post: null,
      selected_ad_id: null,
      facebook_pages: null,
      encoded_fb_post_url: null,
      page_options: [],
      headers: [
        { key: null, title: '' },
        { key: null, title: '' },
        { key: 'created_time', title: 'Time', tooltip: 'Post creation time' },
        { key: 'message', title: 'Message', tooltip: 'Post message' },
        { key: 'likes', icon: 'nexd-icon-16-likes', tooltip: 'Number of likes' },
        { key: 'comments', icon: 'nexd-icon-16-comments', tooltip: 'Number of comments' },
        { key: 'insights', icon: 'nexd-icon-32-seen', tooltip: 'Number of views' },
        { key: 'shares.count', icon: 'nexd-icon-16-shares', tooltip: 'Number of shares' },
      ],
    };
  },

  created() {
    this.getUserFacebookPages();
  },

  methods: {
    async cancel() {
      this.$emit('cancel');
    },

    select(post) {
      if (post.full_picture == null) return;
      this.active_facebook_post = post;
      this.encoded_fb_post_url = 'https://www.facebook.com/plugins/post.php?href=' + encodeURIComponent(post.permalink_url) + '&show_text=true&width=350';
    },

    done() {
      if (!this.active_facebook_page) return;
      this.$emit('select', {
        type: 'facebook',
        page_token: this.active_facebook_page.access_token,
        page_id: this.active_facebook_page.id,
        post_id: this.active_facebook_post.id,
      });
    },

    async getUserFacebookPages() {
      // reset selection
      this.selected_page_id = null;
      this.active_facebook_post = null;

      const status = await this.$parent.validateFacebookConnectionStatus();
      if (status && this.token) {
        this.loading.pages = true;
        const user_id = status.authResponse.userID;

        getUserFacebookPages(user_id, this.token)
          .then(async pages => {
            this.facebook_pages = {};
            for (const page of pages) {
              page.posts = null;
              this.$set(this.facebook_pages, page.id, page);
            }

            for (const page in this.facebook_pages) {
              const key = this.facebook_pages[page].id;
              const value = this.facebook_pages[page].name;
              this.page_options.push({ value: key, label: value });
            }

            // set first option as active, if it exists
            if (this.page_options.length >= 1) {
              await this.$nextTick();
              this.selected_page_id = this.page_options[0].value;
              this.getFacebookPagePosts();
            } else {
              this.no_accessible_pages = true;
            }
          })
          .catch(message => {
            this.$emit('error', message);
          })
          .finally(_ => {
            this.loading.pages = false;
          });
      }
    },

    async getFacebookPagePosts(paging_token = null) {
      if (paging_token == null) {
        // reset selection
        this.active_facebook_post = null;
        this.$set(this.active_facebook_page, 'posts', []);
      }

      const status = await this.$parent.validateFacebookConnectionStatus();
      if (status) {
        if (paging_token == null) {
          this.loading.posts = true;
        } else {
          this.loading.more = true;
        }

        getFacebookPagePosts(this.active_facebook_page.id, this.active_facebook_page.access_token, paging_token)
          .then(({ posts, paging }) => {
            this.paging = paging;

            for (const post of posts) {
              let insights = 0;
              // damn this insight is stupid
              if (post?.insights?.data?.[0]?.values?.length > 0) {
                insights = post.insights.data[0].values[0].value || 0;
              }

              let message = post.message || 'Post has no message';
              // replace all slashes with / + empty char (\u200B) to enable word breaking where the slashes are in URL
              // if this is not working fine, might just add the chart into the longer words
              message = breakLongStrings(message);

              this.active_facebook_page.posts.push({
                id: post.id,
                full_picture: post.full_picture || null,
                created_time: new Date(post.created_time).getTime() / 1000,
                message,
                likes: post.likes != null ? post.likes.summary.total_count : 0,
                comments: post.comments != null ? post.comments.summary.total_count : 0,
                reactions: post.reactions != null ? post.reactions.summary.total_count : 0,
                insights,
                shares: post.shares != null ? post.shares.count : 0,
                permalink_url: post.permalink_url,
              });
            }
          })
          .catch(message => {
            this.$emit('error', message);
          })
          .finally(_ => {
            this.loading.posts = false;
            this.loading.more = false;

            if (paging_token == null) {
              if (this.active_facebook_page.posts.length) {
                this.select(this.active_facebook_page.posts[0]);
              }
            }
          });
      }
    },

    handleTableScrolling() {
      if (this.$refs?.scroll) {
        const pos = this.$refs.scroll.scrollHeight - (this.$refs.scroll.scrollTop + this.$refs.scroll.clientHeight);
        const offset = 100;

        if (pos < offset && !this.loading.pages && !this.loading.more && this.paging?.after) {
          this.getFacebookPagePosts(this.paging.after);
        }
      }
    },
  },
};
</script>
